import React from "react";
import { Link } from "react-scroll";

// Sections
import Hero from "../../components/sections/Hero";
import HeroSeparator from "../../components/sections/HeroSeparator";
import SuccessStories from "../../components/sections/SuccessStories";
import Cta from "../../components/sections/Cta";
import Layout from "../../layouts";
import {workSuccessStoriesData}  from "../../components/sucessStoriesData";

// Images
import bgHero from "../../assets/images/bg-work.jpg";
import lineImg from "../../assets/images/work-icon.svg";

const Work = () => {
	return (
		//react.fragment
		<Layout>
			<Hero hero={bgHero} layout="extended">
				<div className="container h-100 ">
					<div className="hero__inner">
						<h1 className="h1">
							<span>We build</span>
							<span>the right</span>
							<span>things</span>
							<span>well.</span>
						</h1>
						<div className="hero__content">
							<p>
								We will drop-in to your organisation, start assessing the opportunity and navigate any complexities in a flexible and highly skilled manner. Years of enterprise level experience have honed our engineers’ ability to do this together, as a team.
							</p>
							<p>
								We will carry out our work efficiently and we’ll leave you with beautiful user-centric maintainable software solutions. Our processes, like our products, are lovingly designed to thrive and help you achieve your goals.
							</p>
						</div>
					</div>
				</div>
			</Hero>
			<HeroSeparator image={lineImg} />
			<SuccessStories
				id="success-stories"
				title="SUCCESS STORIES"
				storiesDataStatic={workSuccessStoriesData}
			/>
			<Cta />
		</Layout>
	);
};

export default Work;
